var _a, _b, _c;
import { get } from 'lodash';
import { SECTION_CATEGORY, SECTION_HOMEPAGE, SECTION_MY_POSTS, SECTION_POST_LIST, } from './sections';
import { IS_VIEW_COUNT_ENABLED, IS_COMMENT_COUNT_ENABLED, IS_LIKE_COUNT_ENABLED, IS_AUTHOR_NAME_ENABLED, IS_AUTHOR_BADGE_ENABLED, IS_AUTHOR_PICTURE_ENABLED, IS_POST_UPDATED_DATE_ENABLED, IS_CATEGORY_LABELS_ENABLED, IS_POST_TITLE_ENABLED, IS_POST_PUBLISH_DATE_ENABLED, IS_READING_TIME_ENABLED, IS_MORE_OPTIONS_MENU_ENABLED, IS_POST_DESCRIPTION_ENABLED, IS_TAGS_ENABLED, IS_POST_DATE_ENABLED, IS_CATEGORY_LABEL_ENABLED, IS_COVER_IMAGE_ENABLED, AUTHOR_INFO_TYPE, AUTHOR_INFO_TYPE_NAME_PICTURE, } from './wix-params';
var FEED_METADATA_SETTINGS = (_a = {},
    _a[IS_VIEW_COUNT_ENABLED] = true,
    _a[IS_COMMENT_COUNT_ENABLED] = true,
    _a[IS_LIKE_COUNT_ENABLED] = true,
    _a[IS_CATEGORY_LABEL_ENABLED] = false,
    _a[IS_TAGS_ENABLED] = true,
    _a[IS_POST_DESCRIPTION_ENABLED] = true,
    _a[IS_POST_TITLE_ENABLED] = true,
    _a[IS_MORE_OPTIONS_MENU_ENABLED] = true,
    _a[IS_CATEGORY_LABELS_ENABLED] = true,
    _a[IS_POST_UPDATED_DATE_ENABLED] = true,
    _a[IS_POST_DATE_ENABLED] = true,
    _a[IS_POST_PUBLISH_DATE_ENABLED] = true,
    _a[IS_READING_TIME_ENABLED] = true,
    _a[IS_AUTHOR_PICTURE_ENABLED] = true,
    _a[IS_AUTHOR_NAME_ENABLED] = true,
    _a[IS_AUTHOR_BADGE_ENABLED] = true,
    _a[IS_COVER_IMAGE_ENABLED] = true,
    _a[AUTHOR_INFO_TYPE] = AUTHOR_INFO_TYPE_NAME_PICTURE,
    _a);
var FEED_METADATA_SETTINGS_MY_POSTS = (_b = {},
    _b[IS_VIEW_COUNT_ENABLED] = false,
    _b[IS_COMMENT_COUNT_ENABLED] = false,
    _b[IS_LIKE_COUNT_ENABLED] = false,
    _b[IS_CATEGORY_LABEL_ENABLED] = false,
    _b[IS_TAGS_ENABLED] = false,
    _b[IS_POST_DESCRIPTION_ENABLED] = false,
    _b[IS_POST_TITLE_ENABLED] = true,
    _b[IS_MORE_OPTIONS_MENU_ENABLED] = true,
    _b[IS_CATEGORY_LABELS_ENABLED] = false,
    _b[IS_POST_UPDATED_DATE_ENABLED] = true,
    _b[IS_POST_PUBLISH_DATE_ENABLED] = true,
    _b[IS_POST_DATE_ENABLED] = false,
    _b[IS_READING_TIME_ENABLED] = false,
    _b[IS_AUTHOR_PICTURE_ENABLED] = false,
    _b[IS_AUTHOR_NAME_ENABLED] = false,
    _b[IS_AUTHOR_BADGE_ENABLED] = false,
    _b[IS_COVER_IMAGE_ENABLED] = true,
    _b[AUTHOR_INFO_TYPE] = AUTHOR_INFO_TYPE_NAME_PICTURE,
    _b);
var FEED_METADATA_SETTINGS_BY_SECTION = (_c = {},
    _c[SECTION_HOMEPAGE] = FEED_METADATA_SETTINGS,
    _c[SECTION_CATEGORY] = FEED_METADATA_SETTINGS,
    _c[SECTION_POST_LIST] = FEED_METADATA_SETTINGS,
    _c[SECTION_MY_POSTS] = FEED_METADATA_SETTINGS_MY_POSTS,
    _c);
export var getFeedMetadataSettingsDefaultValue = function (wixParam, section) {
    if (section === void 0) { section = SECTION_HOMEPAGE; }
    return get(FEED_METADATA_SETTINGS_BY_SECTION, [section, wixParam]);
};
