import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { debounce } from 'lodash';
import getDisplayName from 'react-display-name';
import { getIsInIFrame } from '../selectors/is-in-iframe';

export const withLayoutUpdate = (WrappedComponent) => {
  class WithLayoutUpdate extends React.Component {
    static displayName = `withLayoutUpdate(${getDisplayName(
      WrappedComponent,
    )})`;

    componentDidMount() {
      if (getIsInIFrame(this.props.state)) {
        return;
      }

      this.node = document.getElementById(this.props.host.id);
      if (!this.node) {
        return;
      }

      this.node.style.minWidth = 0;
      this.observer = new MutationObserver(this.updateLayoutIfNeeded);
      this.observer.observe(this.node, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      });
      this.lastHeight = this.node.offsetHeight;

      window.addEventListener('resize', this.updateLayoutIfNeededDebounced);
      window.addEventListener('transitionend', this.updateLayoutIfNeeded);
    }

    componentWillUnmount() {
      if (getIsInIFrame(this.props.state) || !this.node) {
        return;
      }

      this.observer.disconnect();
      window.removeEventListener('resize', this.updateLayoutIfNeededDebounced);
      window.removeEventListener('transitionend', this.updateLayoutIfNeeded);
    }

    updateLayoutIfNeeded = () => {
      if (this.lastHeight !== this.node.offsetHeight) {
        this.lastHeight = this.node.offsetHeight;
        this.props.host.updateLayout();
      }
    };

    updateLayoutIfNeededDebounced = debounce(this.updateLayoutIfNeeded, 100, {
      leading: true,
    });

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithLayoutUpdate, WrappedComponent);

  return WithLayoutUpdate;
};
