import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { isVerboseDebugAllowed } from '../../services/debug';
import styles from './invalid-app-state-page.scss';

const BACKUP_TRANSLATIONS = {
  'broken-state.title': 'Something Isn’t Working…',
  'broken-state.main-message': 'Refresh the page to try again.',
  'broken-state.btn-text': 'Refresh Page',
  'broken-state.error': 'Error',
};

const formatMessage = (props) =>
  get(props, 'error.message') ||
  get(props, 'workerError', '').toString().split('\n')[0];

export const InvalidAppStatePage = (props) => {
  const t = (key) =>
    get(props, `state.translations[${key}]`, BACKUP_TRANSLATIONS[key]);
  const errorId = props.errorId || props.workerErrorId;
  const message = isVerboseDebugAllowed && formatMessage(props);
  return (
    <section className={styles.container} data-hook="error-page">
      <div tabIndex="0" className={styles.innerContainer}>
        <h2
          className={classNames(styles.title, 'invalid-app-state-page-title')}
        >
          {t('broken-state.title')}
        </h2>
        <span
          className={classNames(styles.body, 'invalid-app-state-page-body')}
        >
          {t('broken-state.main-message')}
        </span>
        <button
          onClick={() => window.location.reload()}
          className={classNames(
            styles.button,
            'button-primary-text-color',
            'button-background-color',
          )}
        >
          {t('broken-state.btn-text')}
        </button>
        {errorId && (
          <span className={styles.error}>
            {t('broken-state.error')}: {errorId}
          </span>
        )}
        {message && <span className={styles.error}>{message}</span>}
      </div>
    </section>
  );
};

InvalidAppStatePage.propTypes = {
  t: PropTypes.func,
};

export default InvalidAppStatePage;
